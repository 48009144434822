//-------------------- Fonts --------------------//

$body-font: 'Poppins', sans-serif;
$title-font: 'Poppins', sans-serif;

//-------------------- Base Colors --------------------//

$primary-color: #40bffa;
$primary-color-hover: darken($primary-color, 7%);
$secondary-color: #939597;
$body-bg: #dddddd;

//-------------------- Social Icons Colors --------------------//

$facebook: #3b5998;
$twitter: #00acee;
$google: #dd4b39;
$linkedin: #0e76a8;
$youtube: #c4302b;
$instagram: #3f729b;
$dribbble: #ea4c89;
$github: #333333;
$behance: #053eff;
$reddit: #c6c6c6;
$pinterest: #cc2127;
$vk: #2b587a;
$rss: #ee802f;
$skype: #00aff0;
$xing: #126567;
$tumblr: #34526f;
$email: #6567a5;
$delicious: #205cc0;
$stumbleupon: #f74425;
$digg: #191919;
$blogger: #fc4f08;
$flickr: #ff0084;
$vimeo: #86c9ef;
$yahoo: #720e9e;
$appstore: #000;
